<template>
  <div class="page">
    <div class="page__title">
      {{ this.$route.meta.title }}
    </div>
    <div class="page__content" v-html="text"></div>
  </div>
</template>
<script>
import applications from '../../json/applications.json'

export default {
  name: "privacy",
  data: function () {
    return {
      text: ""
    };
  },
  methods: {
    getPage: function () {
      const text = require(`@/json/pages/privacy.html`).default;
      const mapObj = {
        '%apps%': Object.values(applications).map(e => e.name).join(", "),
        '%account%': process.env.VUE_APP_ACCOUNT,
        '%email%': process.env.VUE_APP_EMAIL,
        '%url%': process.env.VUE_APP_URL,
      };
      this.text = text.replace(/(%apps%|%account%|%url%|%email%)/gi, matched => mapObj[matched]);
    }
  },
  mounted() {
    this.getPage()
  }
}
</script>
<style lang="less">
@import "style.less";
</style>
import {createWebHistory, createRouter} from "vue-router";

import indexContainer from '@/containers/index';
import privacyContainer from '@/containers/privacy';
import tosContainer from '@/containers/tos';
import feedbackContainer from "@/containers/feedback";

export const routes = [
    {
        name: 'index',
        path: '/',
        component: indexContainer,
        meta: {
            title: process.env.VUE_APP_TITLE,
            keywords: '',
            description: '',
        },
    },
    {
        name: 'feedback',
        path: '/feedback',
        component: feedbackContainer,
        meta: {
            title: process.env.VUE_APP_TITLE,
            keywords: '',
            description: '',
        },
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: privacyContainer,
        meta: {
            title: 'Privacy Policy',
            keywords: '',
            description: '',
        },
    },
    {
        name: 'tos',
        path: '/tos/:id',
        component: tosContainer,
        meta: {
            title: 'Terms & Conditions',
            keywords: '',
            description: '',
        },
    },
    // TODO ссылки для обратной совместимости
    {
        path: '/tos',
        redirect: '/tos/volume'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash }
        }
        return {left: 0, top: 0};
    }
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
});

export default router;